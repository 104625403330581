import React, {Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Spin} from "antd";

import './App.css';
import 'antd/dist/antd.css';
import {AppRoutes} from "./routes/routes";



function App() {
  return (
      <Suspense fallback={<Spin/>}>
          <Router>
              <AppRoutes />
          </Router>
      </Suspense>

  );
}

export default App;
