import React, {useEffect, useState} from 'react';
import {Divider, Typography} from "antd";
import {StyledTitle} from "./styles";
import CustomerApi from "../../common/api/customerApi";
const {Text} = Typography;

const TotalCustomer = () => {
    const [totalCustomer, setTotalCustomer] = useState(0);

    const getTotalCustomer = async () => {
        const result = await CustomerApi.getTotalCustomer();
        if (result.data) {
            setTotalCustomer(result.data || 0);
        }
    }
    useEffect(() => {
        getTotalCustomer();
    }, [])
    return (
        <>
            <Text strong>Toplam Müşteri</Text>
            <Divider/>
            <StyledTitle>{totalCustomer}</StyledTitle>
        </>
    );
};

export default TotalCustomer;