import React from 'react';
import {useRoutes} from "react-router-dom";

import PrivateRoute from "./privateRoute";
import Home from "../modules/home/home";
import CustomerList from "../modules/customers/customerList";
import JobDetail from "../modules/job/jobDetail";
import EditJob from "../modules/job/editJob";
import NewJob from "../modules/job/newJob";
import Login from "../modules/login/login";
import Report from "../modules/report/report";



export const AppRoutes = () => {
    let routes = [
        {
            path: "/",
            element: <PrivateRoute />,
            children: [
                {path: '/', exact: true, element: <Home/>},
                {path: '/home', element: <Home/>},
                {path: '/customers', element: <CustomerList/>},
                {path: '/report', element: <Report/>},
                {path: '/job-detail/:trackNumber', element: <JobDetail/>},
                {path: '/edit-job/:trackNumber', element: <EditJob/>},
                {path: '/new-job', element: <NewJob/>},
            ]
        },
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: '*', element: <h1>404 Not Found</h1>
        }
    ];
    let element = useRoutes(routes);

    return element;
}