import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import JobsApi from "../../common/api/jobsApi";
import JobForm from "../../components/forms/jobForm";
import {Spin} from "antd";
import CustomerFormModal from "../../components/modal/customerFormModal";
import MessageModal from "../../components/modal/messageModal";
import {ROUTES} from "../../common/constants/routeConstants";

const EditJob = (props) => {
    let {trackNumber} = useParams();
    const navigate = useNavigate();
    const [jobDetail, setJobDetail] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [formState, setFormState] = useState({
        failed: false,
        submitted: false,
        finished: false,
        message: '',
        trackNumber: ''
    });

    useEffect(() => {
        getJobDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('track no=>', trackNumber);
        console.log('job detail=>', jobDetail);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobDetail])

    const getJobDetail = () => {
        JobsApi.getJobDetail(trackNumber).then(response => {
            console.log('responxe=>', response.data);
            if (response.data) {
                setJobDetail(response.data);
            }
        }).catch(err => {
            console.error(err);
        })
    }

    const onFormFinish = async (values, form) => {
        console.log('form finished=>', values);
        const response = await JobsApi.updateJob(values, jobDetail.id);
        if (response.data) {
            setFormState({submitted: true, failed: false, finished: true, message: 'Kayıt Başarılı', trackNumber});
        } else {
            setFormState({submitted: true, failed: true, finished: true, message: 'Kayıt Başarısız', trackNumber});
        }
        setTimeout(() => {
            console.log('herree');
            setShowMessageModal(true);
        }, 750)
        console.log('response=>', response.data);
    }

    const onFormFinishFailed = (error) => {
        console.log('error=>', error);
        setFormState({...formState, finished: true, message: 'Girdiğiniz bilgileri kontrol edip tekrar deneyiniz.', failed: true});
    }

    const showMusteriModal = (event) => {
        event.preventDefault();
        setModalTitle('Yeni Müşteri Ekle');
        setIsModalVisible(true);
    };

    const closeMessageModal = (event) => {
        event.preventDefault();
        setShowMessageModal(false);
        navigate(formState.finished && formState.submitted && !formState.failed ? `${ROUTES.JOB_DETAILS}${formState.trackNumber}` : ROUTES.HOME);
    }
    return (
        <Spin spinning={formState.submitted && !formState.finished} tip="Kaydediliyor...">
            {jobDetail && <JobForm onFormFinish={onFormFinish} onFormFinishFailed={onFormFinishFailed} showMusteriModal={showMusteriModal} jobDetail={jobDetail} formTitle={trackNumber}/>}
            <CustomerFormModal title={modalTitle} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
            <MessageModal
                isModalVisible={showMessageModal}
                setIsModalVisible={setShowMessageModal}
                status={formState.submitted && !formState.failed && formState.finished ? 'success' : 'warning'}
                title={formState.submitted && !formState.failed && formState.finished ? `Güncelleme Başarılı!` : 'Kayıt Başarısız'}
                subTitle={formState.submitted && !formState.failed && formState.finished ? `${formState.trackNumber} takip numaralı ürünnler güncellendi.` : (formState.message || 'Güncelleme esnasında bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.')}
                btnType={formState.submitted && !formState.failed && formState.finished ? 'primary' : 'warning'}
                btnLabel={formState.submitted && !formState.failed && formState.finished ? 'Tamam' : 'Anladım'}
                btnClicked={closeMessageModal}
            />
        </Spin>
    );
};

export default EditJob;