import React, {useState} from 'react';
import {
    Spin,
} from "antd";
import 'moment/locale/tr';
import CustomerFormModal from "../../components/modal/customerFormModal";
import JobsApi from "../../common/api/jobsApi";
import MessageModal from "../../components/modal/messageModal";
import {useNavigate} from "react-router";
import {ROUTES} from "../../common/constants/routeConstants";
import JobForm from "../../components/forms/jobForm";



const NewJob = (props) => {

    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [formState, setFormState] = useState({
        failed: false,
        submitted: false,
        finished: false,
        message: '',
        trackNumber: ''
    });
    const [showMessageModal, setShowMessageModal] = useState(false);


    const onFormFinish = (values, form) => {
        setFormState({...formState, submitted: true, finished: false, failed: false, message: 'Bilgiler Kaydediliyor...'})
        JobsApi.createNewJob(values).then((result) => {
            if (result.data.trackNumber) {
                setFormState({submitted: true, failed: false, finished: true, message: 'Kayıt Başarılı', trackNumber: result.data.trackNumber});
            } else {
                setFormState({...formState, finished: true, message: result.data.message, failed: true});
            }
            setTimeout(() => {
                setShowMessageModal(true);
            }, 750)
        }).catch(err => {
            console.error(err);
            setFormState({...formState, finished: true, message: 'Kayıt esnasında bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.', failed: true});
        });
    }

    const onFormFinishFailed = (error) => {
        console.log('error=>', error);
        setFormState({...formState, finished: true, message: 'Girdiğiniz bilgileri kontrol edip tekrar deneyiniz.', failed: true});
    }

    const showMusteriModal = (event) => {
        event.preventDefault();
        setModalTitle('Yeni Müşteri Ekle');
        setIsModalVisible(true);
    };

    const closeMessageModal = (event) => {
        event.preventDefault();
        setShowMessageModal(false);
        navigate(formState.finished && formState.submitted && !formState.failed ? `${ROUTES.JOB_DETAILS}${formState.trackNumber}` : ROUTES.HOME);
    }
    return (
    <Spin spinning={formState.submitted && !formState.finished} tip="Kaydediliyor...">
        <JobForm onFormFinishFailed={onFormFinishFailed} onFormFinish={onFormFinish} showMusteriModal={showMusteriModal} formTitle="Yeni İş"/>
        <CustomerFormModal title={modalTitle} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        <MessageModal
            isModalVisible={showMessageModal}
            setIsModalVisible={setShowMessageModal}
            status={formState.submitted && !formState.failed && formState.finished ? 'success' : 'warning'}
            title={formState.submitted && !formState.failed && formState.finished ? `Kayıt Başarılı! Takip No:${formState.trackNumber}` : 'Kayıt Başarısız'}
            subTitle={formState.submitted && !formState.failed && formState.finished ? "Takip Numarası ile ürünlerin durumunu takip edebilirsiniz." : (formState.message || 'Kayıt esnasında bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.')}
            btnType={formState.submitted && !formState.failed && formState.finished ? 'primary' : 'warning'}
            btnLabel={formState.submitted && !formState.failed && formState.finished ? 'Tamam' : 'Anladım'}
            btnClicked={closeMessageModal}
        />
    </Spin>
    );
};



export default NewJob;
