import React, {useState} from 'react';
import styled from 'styled-components';
import { Card} from "antd";

import LoginForm from "../../components/forms/loginForm";
import useUserActions from "../../common/utils/authUtil";
import {cardTitle} from "../../components/layout/cardTitle";
import MainLayout from "../../components/layout/layout";
import {SMALL_SCREEN} from "../../common/constants/screens";
import {useNavigate} from "react-router";


const StyledSiteCardWrapper = styled.div`
  margin: 10vh auto;
  width: 100%;
  max-width: 475px;

  @media screen and (max-width: ${SMALL_SCREEN}px) {
    max-width: 100%;
  }
`
const StyledErrorMessage = styled.div`
  color: red;
  font-weight: 500;
  text-align: center;
`
function Login(props) {
    const {login} = useUserActions();
    const navigate = useNavigate();

    const [loginFailedMessage, setLoginFailedMessage] = useState();
    const loginHandler = async (formData) => {
        // console.log('form data=>', formData);
        const loginResponse = await login(formData);
        if (!loginResponse?.loginStatus) {
            setLoginFailedMessage(loginResponse.message);
        } else {
            setTimeout(() => {
                navigate('/');
            }, 300);
        }
    }
    return (
        <MainLayout showHeader={false}>
            <StyledSiteCardWrapper>
                <Card title={cardTitle('Giriş Yap')} bordered={false}>
                    <LoginForm onSubmit={loginHandler}/>
                    <StyledErrorMessage>{loginFailedMessage}</StyledErrorMessage>
                </Card>
            </StyledSiteCardWrapper>
        </MainLayout>
    );
}

export default Login;
