import {atom} from "recoil";
import {AUTH_TOKEN} from "../common/constants/apiConstants";

const authAtom = atom({
    key: 'auth',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem(AUTH_TOKEN))
});

export {authAtom}
