import React from 'react';
import {Button, Card, Popover,  Switch, Table} from "antd";
import moment from "moment";
import {
    ArrowUpOutlined,
    IssuesCloseOutlined,
    UnorderedListOutlined,
    ArrowDownOutlined,
    EuroCircleOutlined,
    PhoneOutlined
} from "@ant-design/icons";


const DataTable = (props) => {
    const {data, columns, onUpdatePaymentStatus} = props;

    return (
        <Table  id="jobsTable" expandable={{
            expandedRowRender: (record) => {
                return (
                    <>
                        <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                            <div>
                                <ArrowDownOutlined />{moment(record.checkInDate).format('DD/MM/YYYY')}
                            </div>
                            <div>
                                <ArrowUpOutlined />{moment(record.checkOutDate).format('DD/MM/YYYY')}
                            </div>
                            <div><PhoneOutlined/>&nbsp;
                                <a href={`tel:${record.customer.phoneNumber}`}>
                                    {record.customer.phoneNumber}
                                </a>
                            </div>

                        </div>

                        {record.products.map((product, key) =>
                            <Card key={key}>
                                <div style={{fontWeight: '600'}}>{product.description}</div>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <UnorderedListOutlined />&nbsp;&nbsp;
                                    <div>{product.characteristic.join(', ')}</div>

                                </div>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <IssuesCloseOutlined />&nbsp;&nbsp;
                                    <div>
                                        {product.operations.join(', ')}
                                    </div>
                                </div>
                                <div>
                                    <EuroCircleOutlined />&nbsp;&nbsp;
                                    {product.fee} &nbsp;

                                    <Switch size="small" checked={product.paymentStatus} checkedChildren={'Ödendi'} unCheckedChildren={'Ödenmedi'} onChange={() =>onUpdatePaymentStatus(product)}/>
                                </div>
                                <div>
                                    {
                                        product.notes &&
                                        <Popover
                                            content={product.notes}
                                            title="Notlar"
                                            trigger="click"
                                        ><br/><Button>Ek Notlar</Button></Popover>
                                    }
                                </div>
                            </Card>

                        )}
                    </>

                )
            },
            rowExpandable: (record) => record.name !== 'Not Expandable',
        }} columns={columns} dataSource={data} />
    );
};

export default DataTable;