import React, {useEffect, useState} from 'react';
import {Button, Divider, Space, Typography} from "antd";
import ReportApi from "../../common/api/reportApi";
import {StyledTitle} from "./styles";
const {Text} = Typography;

const TotalIncome = () => {
    const [activeSummary, setActiveSummary] = useState('week');
    const [totalSummary, setTotalSummary] = useState(0);

    const getSummaryReport = (reportType) => {
        ReportApi.getSummaryReport(reportType).then(result => {
            console.log('report=>', result);
            if (result.data) {
                setTotalSummary(result.data?.[0].total || 0);
            }
        });
    }

    useEffect(() => {
        getSummaryReport(activeSummary);
    }, [activeSummary]);

    useEffect(() => {
        getSummaryReport(activeSummary);
    }, []);

    const getSummaryReportHandler = (reportType) => {
        setActiveSummary(reportType)
    }

    return (
        <>
            <Text strong>Toplam Gelir</Text>
            <Divider/>
            <Space>
                <Button size="small" type={activeSummary === 'week' ? 'primary' : 'secondary'} htmlType="button" onClick={() => getSummaryReportHandler('week')}>Haftalık</Button>
                <Button size="small" type={activeSummary === 'month' ? 'primary' : 'secondary'} htmlType="button" onClick={() => getSummaryReportHandler('month')}>Aylık</Button>
                <Button size="small" type={activeSummary === 'year' ? 'primary' : 'secondary'} htmlType="button" onClick={() => getSummaryReportHandler('year')}>Yıllık</Button>
            </Space>
            <StyledTitle>€ {totalSummary}</StyledTitle>
        </>
    );
};

export default TotalIncome;