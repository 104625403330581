import React from 'react';
import {Modal} from "antd";
import CustomerForm from "../forms/customerForm";
import CustomerApi from "../../common/api/customerApi";

const CustomerFormModal = (props) => {

    const {title, isModalVisible, setIsModalVisible} = props;

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFormSuccess = async (values) => {
        const customerResponse = await CustomerApi.createCustomer(values);
        setIsModalVisible(false);
    }

    const onFormCanceled = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal
            title={title}
            visible={isModalVisible}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}>
            <CustomerForm formSuccess={onFormSuccess} formCanceled={onFormCanceled}/>
        </Modal>
    );
};

export default CustomerFormModal;