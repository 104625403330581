import React, {useEffect, useRef, useState} from 'react';
import {Modal, Upload} from "antd";
import styled from 'styled-components';
import Webcam from "react-webcam";
import {SMALL_SCREEN} from "../../common/constants/screens";
import {UploadProps} from "antd";

const StyledTakePhotoBtn = styled.div`
  position: relative;
  #container-circles{position:absolute;left:50%;bottom:90px}
  #outer-circle{left:-37px;height:75px;width:75px;background-color:hsla(0,0%,100%,.4);z-index:1}
  #inner-circle,#outer-circle{position:absolute;border-radius:50%}
  #inner-circle{left:50%;top:38px;height:44px;width:44px;background:#fff;margin:-22px 0 0 -22px;z-index:2}
`;

const StyledModal = styled(Modal)`
  @media screen and (max-width: ${SMALL_SCREEN}px) {
    top: 10px;
    .ant-modal-body {
      padding: 15px 0;
    }
  }
`;

const videoConstraints = {
    facingMode: { exact: "environment" }
};
const CameraModal = (props) => {
    const {isModalVisible, setIsModalVisible, onSuccessPhotos} = props;
    const [imgList, setImgList] = useState([]);
    const [isCamVisible, setIsCamVisible] = useState(true);
    const camera = useRef(null);

    const handleOk = () => {
        onSuccessPhotos(Object.assign([], imgList));
        setImgList([]);
        handleCancel();
    };
    const handleCancel = () => {
        setIsCamVisible(false);
        setTimeout(() => setIsModalVisible(false), 300)
    }

    useEffect(() => {
        setIsCamVisible(isModalVisible);
    }, [isModalVisible]);


    function handleTakePhotoAnimationDone (dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto');
        setImgList([...imgList, {
            url: dataUri,
            name: `${Date.now().toString(16)}.jpeg`,
            status: 'done',
            uid: Date.now().toString(16),
        }]);
    }

    const handlePreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        // eslint-disable-next-line no-unused-expressions
        imgWindow?.document.write(image.outerHTML);
    };

    const handleChange: UploadProps = ({ fileList: newFileList }) =>
        setImgList(newFileList);


    return (
        <StyledModal
            width="100%"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            wrapClassName="camera-modal"
        >
                {
                    <>
                        <Upload
                            listType="picture-card"
                            fileList={imgList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {null}
                        </Upload>
                    </>
                }
                {
                    isCamVisible ?
                        <>
                            <Webcam
                                audio={false}
                                height={'auto'}
                                screenshotFormat="image/jpeg"
                                width={'100%'}
                                videoConstraints={videoConstraints}
                                ref={camera} >
                                {({ getScreenshot }) => (
                                    <StyledTakePhotoBtn
                                        onClick={() => {
                                            const imageSrc = getScreenshot();
                                            handleTakePhotoAnimationDone(imageSrc);
                                        }}
                                    >
                                        <div id="container-circles">
                                            <div id="outer-circle"></div>
                                            <div id="inner-circle"></div>
                                        </div>
                                    </StyledTakePhotoBtn>
                                )}
                            </Webcam>
                        </> : null
                }
        </StyledModal>
    )
}


export default CameraModal;
