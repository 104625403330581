import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Layout, Menu} from 'antd';
import {
    FundOutlined,
    HomeOutlined,
    SafetyOutlined,
    LogoutOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {SMALL_SCREEN} from "../../common/constants/screens";
import {useLocation, useNavigate} from "react-router";
import useUserActions from "../../common/utils/authUtil";
import ChangePasswordModal from "../modal/changePasswordModal";

const {Header, Content, Footer} = Layout;
const navMenu = [{
    key: 'home',
    label: 'Ana Sayfa',
    icon: <HomeOutlined/>
}, {
    key: 'customers',
    label: 'Müşteriler',
    icon: <ProfileOutlined/>
}, {
    key: 'report',
    label: 'Raporlar',
    icon: <FundOutlined />
}];

const StyledMainLayout = styled(Layout)`
  min-height: 100vh;
  height: 100%;
  width: 100%;
`

const StyledMainContent = styled(Content)`
  padding: 0 50px;

  @media screen and (max-width: ${SMALL_SCREEN}px) {
    padding: 0 10px;
  }
`;

const StyledMenu = styled(Menu)`
  @media screen and (max-width: ${SMALL_SCREEN}px) {
    .ant-menu-title-content {
      display: none;
    }
    
    .ant-menu-item .anticon {
      font-size: 20px;
    }
  }
`;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .logout {
    font-size: 20px;
    cursor: pointer;
    color: rgb(255, 255, 255, .7);
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
    margin-right: 20px;
  }

  @media screen and (max-width: ${SMALL_SCREEN}px) {
    padding: 0 20px;
    .logout {
      font-size: 20px;
    }
  }
`

const MainLayout = (props) => {

    const [selectedMenu, setSelectedMenu] = useState(['home']);
    const [passwordModalVisible, setPasswordModalVisible] = useState(false);
    const userAction = useUserActions();
    const navigate = useNavigate();
    const location = useLocation();

    const menuClickHandler = ({ key }) => {
        navigate(`/${key}`);
    }
    useEffect(() => {
        const menuKey = navMenu.find(o => location.pathname.includes(o.key));
        if (menuKey) {
            setSelectedMenu([menuKey.key]);
        } else {
            setSelectedMenu([]);
        }
    }, [location]);
    const logout = (e) => {
        e.preventDefault();
        console.log('here');
        userAction.logout();
    }

    const changePassword = () => {
        setPasswordModalVisible(true);
    }

    const passwordModalCloseHandler = (status) => {
        setPasswordModalVisible(false);
        if (status) {
            userAction.logout();
        }
    }
    return (
        <StyledMainLayout>
            {
                props.showHeader &&
                <StyledHeader className="header">
                    {/*<div className="logo"/>*/}
                    <StyledMenu theme="dark" mode="horizontal"
                                items={navMenu}
                                selectedKeys={selectedMenu}
                                onSelect={({key}) => setSelectedMenu([key])}
                                onClick={menuClickHandler}/>
                    <div>
                        <SafetyOutlined onClick={changePassword} className="logout"/>
                        <LogoutOutlined onClick={logout} className="logout"/>
                    </div>

                </StyledHeader>
            }
            <ChangePasswordModal isModalVisible={passwordModalVisible} title="Kullanıcı Bilgileri" setIsModalVisible={passwordModalCloseHandler}/>
            <StyledMainContent>
                {props.children}
            </StyledMainContent>
        </StyledMainLayout>
    )
}

export default MainLayout;