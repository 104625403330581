import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Card, Col, Row, Space, Table, Tag, Typography} from "antd";
import TotalIncome from "../../components/reportWidgets/totalIncome";
import JobSummary from "../../components/reportWidgets/jobSummary";
import ActualJobSummary from "../../components/reportWidgets/actualJobSummary";
import TotalCustomer from "../../components/reportWidgets/totalCustomer";
import DebounceSelect from "../../components/selectBox/selectBox";
import fetchCustomerList from "../../common/utils/getCustomerListUtil";
import CustomerApi from "../../common/api/customerApi";
import DataTable from "../../components/tables/dataTable";
import {Link} from "react-router-dom";
import {JOB_STATUS} from "../../common/constants/misc";

const {Text} = Typography;

const StyledCard = styled(Card)`
  min-height: 230px;
`

const TITLES = {
    trackNumber: 'Takip No',
    checkInDate: 'Geliş',
    status: 'Durum',
    total: 'Toplam'
}


const Report = (props) => {

    const [value, setValue] = useState();
    const [customerReport, setCustomerReport] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);
    const [spinning, setSpinning] = useState(false);

    const configDataColumns = () => {
        setDataColumns([
            {
                title: TITLES.trackNumber,
                dataIndex: 'trackNumber',
                key: 'trackNumber',
                responsive: ['xs', 'sm', 'md', 'lg'],
                render: (data) => <Link to={`/job-detail/${data}`}>{data}</Link>,
            },
            {
                title: TITLES.checkInDate,
                dataIndex: 'checkInDate',
                key: 'checkInDate',
                responsive: [ 'sm', 'md', 'lg'],
                render: (data) => <Text>{moment(data).format('DD/MM/YY')}</Text>,
            },
            {
                title: TITLES.status,
                dataIndex: 'status',
                status: 'status',
                key: 'status',
                responsive: ['xs', 'sm', 'md', 'lg'],
                sorter: (a, b) => a.status - b.status,
                render: (status) => <Tag color={JOB_STATUS[status].COLOR}>{JOB_STATUS[status].TEXT}</Tag>
            },
            {
                title: TITLES.total,
                dataIndex: 'total',
                key: 'total',
                responsive: ['xs', 'sm', 'md', 'lg'],
                sorter: (a, b) => a.total - b.total,
                render: (data) => <Text>€ {data}</Text>
            },
        ])
    }


    useEffect(() => {
        console.log(value);
        if (value?.value) {
            setSpinning(true);
            CustomerApi.getCustomerReport(value.value).then(result => {
                console.log('reulst=>', result);
                if (result.data) {
                    setCustomerReport(result.data.map(data => ({...data, name: 'Not Expandable', key: data.trackNumber})));
                    configDataColumns();
                    setSpinning(false);
                }
            });
        }
    }, [value]);

    useEffect(() => {
        console.log('customer report=>', customerReport);
    }, [customerReport]);
    return (
        <>
            <Row gutter={{ xs: 1, sm: 16, md: 24, lg: 32 }} style={{marginTop: '20px'}}>
                <Col className="gutter-row"  xs={24} sm={24} md={8} lg={6} xl={6}>
                    <StyledCard>
                        <TotalIncome/>
                    </StyledCard>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={8} lg={6} xl={6}>
                    <StyledCard>
                        <TotalCustomer/>
                    </StyledCard>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={8} lg={6} xl={6}>
                    <StyledCard>
                        <ActualJobSummary/>
                    </StyledCard>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={8} lg={6} xl={6}>
                    <StyledCard>
                        <JobSummary/>
                    </StyledCard>
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Col span={24}>
                    <Card>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <DebounceSelect
                                showSearch
                                value={value}
                                placeholder="Müşteri Seç"
                                fetchOptions={fetchCustomerList}
                                notFoundContent={<Text>Müşteri Bulunamadı</Text>}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                            {/*<DataTable data={customerReport} columns={dataColumns} loading={spinning}/>*/}
                            <Table columns={dataColumns} dataSource={customerReport} />;
                        </Space>
                    </Card>
                </Col>
            </Row>
        </>

    );
}

export default Report;