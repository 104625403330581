import React, {useEffect, useState} from 'react';
import {Button, Divider, Space, Typography} from "antd";
import {StyledTitle} from "./styles";
import ReportApi from "../../common/api/reportApi";
const {Text} = Typography;

const JobSummary = () => {
    const [activeJobSummary, setActiveJobSummary] = useState('week');
    const [totalJobSummary, setTotalJobSummary] = useState(0);

    const getJobSummaryReport = (reportType) => {
        ReportApi.getJobSummaryReport(reportType).then(result => {
            console.log('report=>', result);
            if (result.data) {
                setTotalJobSummary(result.data?.[0].total || 0);
            }
        });
    }
    useEffect(() => {
        getJobSummaryReport(activeJobSummary)
    }, []);

    useEffect(() => {
        getJobSummaryReport(activeJobSummary);
    }, [activeJobSummary]);

    const getJobSummaryReportHandler = (reportType) => {
        setActiveJobSummary(reportType)
    }

    return (
        <>
            <Text strong>Tamamlanan İş</Text>
            <Divider/>
            <Space>
                <Button size="small" type={activeJobSummary === 'week' ? 'primary' : 'secondary'} htmlType="button" onClick={() => getJobSummaryReportHandler('week')}>Haftalık</Button>
                <Button size="small" type={activeJobSummary === 'month' ? 'primary' : 'secondary'} htmlType="button" onClick={() => getJobSummaryReportHandler('month')}>Aylık</Button>
                <Button size="small" type={activeJobSummary === 'year' ? 'primary' : 'secondary'} htmlType="button" onClick={() => getJobSummaryReportHandler('year')}>Yıllık</Button>
            </Space>
            <StyledTitle>{totalJobSummary}</StyledTitle>
        </>
    );
};

export default JobSummary;