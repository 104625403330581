import React from 'react';
import {Button, Col, Form, Input, Row} from "antd";

const CustomerForm = (props) => {
    const {formSuccess, formCanceled} = props
    const onFinish = (values) => {
        // console.log('Success:', values);
        formSuccess(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onCancel = () => {
        formCanceled();
    }
    return (
        <Form
            name="basic"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                phoneNumber: '05'
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Oda Numarası"
                name="fullName"
                rules={[
                    {
                        message: 'Lütfen Oda Numarası Giriniz',
                        min: 2,
                        max: 250
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Telefon"
                name="phoneNumber"
                rules={[
                    {
                        message: 'Lütfen telefon no giriniz',
                        min: 0,
                        max: 11
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="E-Posta"
                name="email"
                rules={[{ type: 'email' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item style={{width: '100%'}}>
                <Row justify="end">

                    <Col span={12} md={12} lg={12} xs={24}>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            Kaydet
                        </Button>
                    </Col>

                    <Col span={12} md={12} lg={12} xs={24}>
                        <Button type="secondary" htmlType="button" style={{width: '100%'}} onClick={onCancel}>
                            Vazgeç
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
        );
};

export default CustomerForm;
