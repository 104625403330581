import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Divider, Typography, Tag, Space} from "antd";
import {StyledTitle} from "./styles";
import ReportApi from "../../common/api/reportApi";
import {CheckCircleOutlined, ClockCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {JOB_STATUS} from "../../common/constants/misc";
const {Text} = Typography;

const StyledTag = styled(Tag)`
  font-size: 17px;
  padding: 6px;
`
const ActualJobSummary = () => {
    const [totalActualJobs, setActualJobs] = useState([]);

    useEffect(() => {
        ReportApi.getActualJobSummaryReport().then(result => {
            console.log('result', result);
            if (result.data) {
                setActualJobs(result.data);
            }
        })
    }, [])
    return (
        <>
            <Text strong>Aktif İş</Text>
            <Divider/>
            <StyledTitle>{totalActualJobs.map((job, key) =>
                <Space key={key}>
                    {
                        job.status === 0 &&
                        <StyledTag icon={<ClockCircleOutlined />} color={JOB_STATUS[job.status].COLOR}>
                            {job.total} {JOB_STATUS[job.status].TEXT}
                        </StyledTag>
                    }
                    {
                        job.status === 1 &&
                        <StyledTag icon={<SyncOutlined spin/>} color={JOB_STATUS[job.status].COLOR}>
                            {job.total} {JOB_STATUS[job.status].TEXT}
                        </StyledTag>
                    }
                    {
                        job.status === 2 &&
                        <StyledTag icon={<CheckCircleOutlined />} color={JOB_STATUS[job.status].COLOR}>
                            {job.total} {JOB_STATUS[job.status].TEXT}
                        </StyledTag>
                    }
                </Space>
            )}
            </StyledTitle>
        </>
    );
};

export default ActualJobSummary;