import React from 'react';
import {Navigate, Outlet, } from "react-router-dom";
import {useRecoilValue} from "recoil";
import {authAtom} from "../state/auth.atom";
import MainLayout from "../components/layout/layout";


const PrivateRoute = () => {
    const isLoggedin = useRecoilValue(authAtom);
     return  isLoggedin ?
         <MainLayout showHeader={true}>
            <Outlet/>
        </MainLayout>
        :
        <Navigate to="/login" />;
};

export default PrivateRoute;