import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import DataTable from "../../components/tables/dataTable";
import locale from "antd/es/locale/tr_TR";
import {Layout, Card, Button, Spin, Dropdown, Space, Menu, Input, DatePicker} from "antd";
import Highlighter from 'react-highlight-words';
import {
    PlusCircleOutlined,
    ProfileOutlined,
    DownOutlined, SearchOutlined
} from '@ant-design/icons';
import moment from "moment";

import {cardTitle} from "../../components/layout/cardTitle";
import CustomerFormModal from "../../components/modal/customerFormModal";
import JobsApi from "../../common/api/jobsApi";
import {JOB_STATUS} from "../../common/constants/misc";
import {SMALL_SCREEN} from "../../common/constants/screens";

const {RangePicker} = DatePicker;


const StyledButtonWrapper = styled.div`
  margin-top: 20px;

  button {
    margin-left: 10px;
  }
`;

const StyledButtonLabel = styled.span``;
const StyledCard = styled(Card)`
  margin-top: 20px;

  @media screen and (max-width: ${SMALL_SCREEN}px) {
    .ant-card-body {
      padding: 10px;
    }
  }
  
`;

const TITLES = {
    trackNumber: 'Takip No',
    checkInDate: 'Geliş Tarihi',
    checkOutDate: 'Teslim Tarihi',
    status: 'Durumu',
    customer: 'Müşteri',
    products: 'Ürünler',
    createdAt: 'Kayıt Tarihi',
    id: 'ID',
    detail: 'Detay'
}



const Home = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [jobList, setJobList] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);

    const [spinning, setSpinning] = useState(false);

    const configDataColumns = () => {
        setDataColumns([
            {
                title: TITLES.trackNumber,
                dataIndex: 'trackNumber',
                key: 'trackNumber',
                responsive: ['xs', 'sm', 'md', 'lg'],
                render: (data) => <Link to={`/job-detail/${data}`}>{data}</Link>,
                getColumnSearchProps: getColumnSearchProps('trackNumber', TITLES.trackNumber)
            },
            {
                title: TITLES.customer,
                dataIndex: 'customerName',
                key: 'customerName',
                responsive: ['xs', 'sm', 'md', 'lg'],
                ...getColumnSearchProps('customerName', TITLES.customer)
            },
            {
                title: TITLES.status,
                dataIndex: 'status',
                status: 'status',
                responsive: ['xs', 'sm', 'md', 'lg'],
                sorter: (a, b) => a.status - b.status,
                render: (_, record) => <Dropdown overlay={() =>
                    <Menu onClick={(e) => handleMenuClick(e, record)}
                          items={Object.keys(JOB_STATUS).map(o => ({
                              label: JOB_STATUS[o].TEXT,
                              key: o,
                              value: o
                          }))}/>
                } trigger={['click']} on>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <a onClick={(e) => {
                        e.preventDefault();
                    }}>
                        <Space>
                            {JOB_STATUS[_].TEXT}
                            <DownOutlined/>
                        </Space>
                    </a>
                </Dropdown>

            }
        ])
    }

    const handleMenuClick = (e, data) => {
        setSpinning(true)
        JobsApi.updateJobStatus(Number(e.key), data.trackNumber).then(response => {
            console.log('response =>', response.data);
            if (response.data) {
                setJobList((prevState) => {
                    const tempState = Object.assign([], prevState);
                    const job = tempState.find(o => o.id === data.id);
                    if (job) {
                        job.status = Number(e.key);
                    }
                    return tempState;
                });
                configDataColumns();
            }
            setSpinning(false)
        });
    }
// `checkInDate=2022-05-01&checkOutDate=2022-09-01`

    const getData = (queryParams?) => {
        setSpinning(true);
        JobsApi.getJobList(queryParams).then(result => {
            console.log(result);
            setJobList(result.data.map(data => ({...data, key: data.id, customerName: data.customer.fullName})));
            configDataColumns();
        });
        setSpinning(false);
    }


    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showMusteriModal = () => {
        setModalTitle('Yeni Müşteri Ekle');
        setIsModalVisible(true);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, label) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${label} Ara`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Ara
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Sıfırla
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrele
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const updatePaymentStatusHandle = async (product) => {
        console.log('product=>', product);
        const response = await JobsApi.updateProductPaymentStatus(product.id, !product.paymentStatus)
        console.log('response=>', response);
        if (response.data) {
            setJobList((prevState) => {
                const tempState = Object.assign([], prevState);
                tempState.forEach(o => {
                    const fProduct = o.products.find(p => p.id === product.id);
                    if (fProduct) {
                        fProduct.paymentStatus = !product.paymentStatus;
                    }
                });
                return tempState;
            });
        }
    }

    const filterDateChangeHandler = (event) => {
        console.log('event=>', event);
        if (event) {
            console.log('event=>', event[0].format('YYYY-MM-DD'));
            getData(`checkInDate=${event[0].format('YYYY-MM-DD')}&checkOutDate=${event[1].format('YYYY-MM-DD')}`);
        } else {
            getData();
        }
    }
// `checkInDate=2022-05-01&checkOutDate=2022-09-01`


    return (
        <Spin spinning={spinning}>
            <Layout style={{marginTop: 20}}>
                {/*<PdfModal isModalVisible data={jobList?.[1]}/>*/}
                <StyledButtonWrapper>
                    <Button type="primary" shape="round" icon={<PlusCircleOutlined/>}
                            size={'large'} onClick={() => navigate('/new-job')}>
                        <StyledButtonLabel>Yeni İş</StyledButtonLabel>
                    </Button>
                    <Button onClick={showMusteriModal} type="secondary" shape="round" icon={<ProfileOutlined/>}
                            size={'large'}>
                        <StyledButtonLabel>Yeni Müşteri</StyledButtonLabel>
                    </Button>
                </StyledButtonWrapper>

                <StyledCard title={cardTitle('İş Listesi')} bordered={false}>
                    <>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <RangePicker format="DD/MM/yyyy" locale={locale} style={{
                                width: '100%',
                            }} placeholder={["Başlangıç", "Bitiş"]} onChange={filterDateChangeHandler}/>
                            <DataTable data={jobList} columns={dataColumns} loading={spinning} onUpdatePaymentStatus={updatePaymentStatusHandle}/>

                        </Space>
                    </>
                </StyledCard>

                <CustomerFormModal title={modalTitle} isModalVisible={isModalVisible}
                                   setIsModalVisible={setIsModalVisible}/>

            </Layout>
        </Spin>
    );
}


export default Home;