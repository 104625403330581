export const ENDPOINTS = {
    CUSTOMER: {
        CREATE_CUSTOMER: '/customer',
        FIND_CUSTOMER: '/customer/find',
        GET_CUSTOMER_LIST: '/customer/list',
        UPDATE_CUSTOMER: '/customer',
        DELETE_CUSTOMER: '/customer'
    },
    JOB: {
        GET_JOB_LIST: '/jobs/list',
        CREATE_NEW_JOB: '/jobs/new-job',
        GET_JOB_DETAIL: '/jobs',
        SET_PRODUCT_PHOTOS: '/jobs/product-photo',
        PRODUCT_PHOTO: '/jobs/product/photo',
        UPDATE_STATUS: '/jobs/status',
        UPDATE_PAYMENT_STATUS: '/jobs/payment-status',
        UPDATE_JOB: '/jobs'
    },
    PHOTO: {
        UPLOAD: '/jobs/upload',
        DELETE: '/jobs/photo'
    },
    USER: {
        LOGIN: '/auth/login',
        CHANGE_PASSWORD: '/auth/password-change'
    },
    REPORT: {
        GET_SUMMARY: '/jobs/report/summary',
        GET_JOB_SUMMARY: '/jobs/report/job-summary',
        GET_ACTUAL_JOB_SUMMARY: '/jobs/report/actual-job-summary',
        GET_TOTAL_CUSTOMER: 'customer/report/total-customer',
        GET_CUSTOMER_REPORT: 'customer/report'
    }
};

export const AUTH_TOKEN = 'user';
export const USER_TOKEN = 'x-auth-token';
