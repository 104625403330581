import {
    CheckCircleOutlined, CloseCircleOutlined,
    IssuesCloseOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    StopOutlined
} from "@ant-design/icons";
import React from "react";

export const CURRENCY = 'EUR';

export const JOB_STATUS = {
    0: {TEXT: 'Alındı', COLOR: 'orange', ICON: <PauseCircleOutlined/>},
    1: {TEXT: 'İşlemde', COLOR: 'blue', ICON: <PlayCircleOutlined/>},
    2: {TEXT: 'Bitti', COLOR: 'green', ICON: <IssuesCloseOutlined/>},
    3: {TEXT: 'Teslim Edildi', COLOR: 'gray', ICON: <CheckCircleOutlined/>},
    4: {TEXT: 'İptal Edildi', COLOR: 'red', ICON: <StopOutlined/>},
    5: {TEXT: 'İade Edildi', COLOR: 'purple', ICON: <CloseCircleOutlined/>}
};

export const PAYMENT_STATUS = {
    true: {TEXT: 'Ödendi', COLOR: 'green'},
    false: {TEXT: 'Ödenmedi', COLOR: 'red'}
}

export const CURRENCY_SYMB= {
    EUR: '€',
    USD: '$',
    TRY: '₺'
}