import {ENDPOINTS} from "../constants/apiConstants";
import instance from "../http/httpUtil";
import HttpUtil from "../http/httpUtil";

class ReportApi {
    async getSummaryReport(reportType) {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.REPORT.GET_SUMMARY}/${reportType}`,
        };
        return await HttpUtil.makeRequest(request)
    }
    async getJobSummaryReport(reportType) {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.REPORT.GET_JOB_SUMMARY}/${reportType}`,
        };
        return await HttpUtil.makeRequest(request)
    }

    async getActualJobSummaryReport() {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.REPORT.GET_ACTUAL_JOB_SUMMARY}`,
        };
        return await HttpUtil.makeRequest(request)
    }
}

export default new ReportApi();