import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CustomerApi from "../../common/api/customerApi";
import {Card, Input, List, Popconfirm, Space} from "antd";
import {
    CloseOutlined,
    DeleteFilled,
    EditFilled,
    MailFilled,
    PhoneFilled, SaveFilled,
    SkinOutlined,
    UserOutlined
} from "@ant-design/icons";
import {cardTitle} from "../../components/layout/cardTitle";
import {SMALL_SCREEN} from "../../common/constants/screens";

const StyledCard = styled(Card)`
  @media screen and (max-width: ${SMALL_SCREEN}px) {
    .ant-card-body {
      padding: 0 5px 10px 0;
    }
  }
`

const StyledList = styled(List)`
  @media screen and (max-width: ${SMALL_SCREEN}px) {
    .ant-list-item-action {
      li {
        padding: 0 20px 10px 0;

        .ant-list-item-action-split {
          right: 10px;
        }
      }
    }
  }
`;

const StyledIClicableSpan = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
// class=".ant-list-vertical .ant-list-item-action > li "

const CustomerList = () => {
    const [customerList, setCustomerList] = useState([]);
    const [editingCustomer, setEditingCustomer] = useState();
    const [search, setSearch] = useState('');
    useEffect(() => {
        getCustomerList();
    }, []);

    const IconText = ({icon, text}) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    const getCustomerList = () => {
        CustomerApi.getCustomerList().then(response => {
            if (response.data) {
                setCustomerList(response.data);
            }
        });
    }

    const editCustomer = (e, customer) => {
        e.preventDefault();
        setEditingCustomer(customer);
    }

    const deleteCustomer = async (e, customer) => {
        const response = await CustomerApi.deleteCustomer(customer.id);
        getCustomerList();
    }

    const editingHandleChange = (event) => {
        setEditingCustomer({...editingCustomer, [event.target.name]: event.target.value});
    }

    const saveEditingHandle = async (event) => {
        event.preventDefault()
        const {fullName, email, phoneNumber, id} = editingCustomer
        const response = await CustomerApi.updateCustomer({fullName, email, phoneNumber}, id);
        console.log(response);
        setEditingCustomer(null);
        getCustomerList();
    }
    const cancelEditingHandle = (event) => {
        event.preventDefault()
        setEditingCustomer(null);
    }

    const searchHandle = (event) => {
        setSearch(event.target.value);
    }
    return (
        <StyledCard title={cardTitle('Müşteri Listesi')} style={{marginTop: '20px'}}>
            <Input.Search allowClear placeholder="Müşteri ara..." onChange={searchHandle}/>
            <StyledList
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 20,
                }}
                dataSource={customerList.filter(o => search.length > 0 ? o.fullName.toLowerCase().includes(search.toLowerCase()) || o.phoneNumber.includes(search) || o.email.toLowerCase().includes(search.toLowerCase()) : o)}
                renderItem={(item) => (
                    <List.Item
                        key={item.id}
                        actions={[
                            (editingCustomer && editingCustomer.id === item.id ?
                                <Input value={editingCustomer.phoneNumber} onChange={editingHandleChange} name="phoneNumber"/> :
                                <IconText icon={PhoneFilled}
                                          text={<a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>}
                                          key="list-vertical-star-o"/>),
                            (editingCustomer && editingCustomer.id === item.id ?
                                <Input value={editingCustomer.email} onChange={editingHandleChange} name="email"/> :
                                <IconText icon={MailFilled} text={<a href={`mailto:${item.email}`}>{item.email}</a>}
                                          key="list-vertical-like-o"/>),
                            <IconText icon={SkinOutlined} text={item.jobsCount} key="list-vertical-skin-o"/>,
                            (editingCustomer && editingCustomer.id === item.id ?
                                <IconText icon={SaveFilled} text={
                                    <Popconfirm placement="bottom" title="Değişiklikler kaydedilsin mi?"
                                                onConfirm={saveEditingHandle} okText="Evet" cancelText="Hayır">
                                        <StyledIClicableSpan>Kaydet</StyledIClicableSpan>
                                    </Popconfirm>
                                }
                                          key="list-vertical-save-o"/> :
                                <IconText icon={EditFilled} text={<StyledIClicableSpan
                                    onClick={(e) => editCustomer(e, item)}>Düzenle</StyledIClicableSpan>}
                                          key="list-vertical-edit-o"/>),
                            (editingCustomer && editingCustomer.id === item.id ? <IconText icon={CloseOutlined}
                                                                                           text={<StyledIClicableSpan
                                                                                               onClick={(e) => cancelEditingHandle(e)}>Vazgeç</StyledIClicableSpan>}
                                                                                           key="list-vertical-cancel-o"/> :
                                <IconText icon={DeleteFilled} text={
                                    <Popconfirm placement="bottom" title="Müşteri silinecek emin misin?"
                                                onConfirm={(e) => deleteCustomer(e, item)} okText="Evet"
                                                cancelText="Hayır">
                                        <StyledIClicableSpan>Sil</StyledIClicableSpan>
                                    </Popconfirm>
                                }
                                          key="list-vertical-delete-o"/>)
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<UserOutlined/>}
                            title={
                                editingCustomer && editingCustomer.id === item.id ?
                                    <Input name="fullName" value={editingCustomer.fullName}
                                           onChange={editingHandleChange}/> :
                                    <span>{item.fullName}</span>
                            }
                        />
                    </List.Item>
                )}
            />
        </StyledCard>
    );
};

export default CustomerList;