import React, {useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Typography} from "antd";
import useUserActions from "../../common/utils/authUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
const {confirm} = Modal;
const {Text} = Typography;

const ChangePasswordModal = (props) => {
    const {title, isModalVisible, setIsModalVisible} = props;
    const [errorMessage, setErrorMessage] = useState();
    const {changePassword} = useUserActions();
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onFinish = async (values) => {
        console.log('Success:', values);

        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Şifre Değitirilecek Emin misin?',

            onOk() {
                changePassword(values).then(response => {
                    console.log('response=>', response);

                    if (response) {
                        setIsModalVisible(true);
                    } else {
                        setErrorMessage('Bir Hata oluştu şifre değiştirilemedi');
                        setTimeout(() => {
                            setIsModalVisible(false);
                        }, 2000)
                    }
                })
            },

            onCancel() {
                setIsModalVisible(false);
            },
        });


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setIsModalVisible(false);
    };

    const onCancel = () => {
        setIsModalVisible(false);
    }
    return (
        <Modal
            title={title}
            visible={isModalVisible}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Form
                name="basic"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    phoneNumber: '05'
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="E-posta"
                    name="email"
                    rules={[
                        {
                            message: 'Lütfen e-posta adresi giriniz',
                            min: 4,
                            max: 500,
                            type: 'email'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Eski Şifre"
                    name="oldPassword"
                    rules={[
                        {
                            required: true,
                            min: 6,
                            message: 'Eski Şifre Giriniz',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Yeni Şifre"
                    name="password"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            min: 6,
                            message: 'Yeni Şifre Giriniz',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Yeni Şifre Onay"
                    name="confirmPassword"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            min: 6,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Girmiş olduğunuz şifreyle uyuşmuyor!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item style={{width: '100%'}}>
                    <Row justify="between">

                        <Col span={24} md={12} lg={12} xs={24}>
                            <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                                Kaydet
                            </Button>
                        </Col>

                        <Col span={24} md={12} lg={12} xs={24}>
                            <Button type="secondary" htmlType="button" style={{width: '100%'}} onClick={onCancel}>
                                Vazgeç
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            <Text strong>{errorMessage}</Text>
        </Modal>
    );
};

export default ChangePasswordModal;