import React from 'react';
import {Button, Modal, Result} from "antd";

const MessageModal = (props) => {
    const { isModalVisible, setIsModalVisible, status, title, subTitle, btnLabel, btnType, btnClicked} = props;

    const handleOk = () => {
        setIsModalVisible(false);
    };

    return (
        <Modal
            visible={isModalVisible}
            footer={null}
            onOk={handleOk}>
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={[
                    <Button type={btnType} key="console" onClick={(e) => btnClicked(e)}>
                        {btnLabel}
                    </Button>
                ]}
            />
        </Modal>
    );
};

export default MessageModal;