import React from 'react';
import styled from 'styled-components';
import moment from "moment";

const StyledCardTitleJobDetail = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  .title {
    font-size: 18px;
    font-weight: 800;  
  }
  .subTitle {
    font-size: 12px;
    font-weight: 300;
    white-space: break-spaces;
  }
  .subTitle2 {
    font-size: 11px;
    font-weight: 500;
    white-space: break-spaces;
    align-self: self-end;
  }  
`
const CardTitle = (props) => {
    const {title, checkInDate, checkOutDate, trackNumber} = props
    return (
        <StyledCardTitleJobDetail>
            <div>
                <div className="title">
                    {title}
                </div>
                <div className="subTitle">
                    {moment(checkInDate).format('DD/MM/YYYY') } - {moment(checkOutDate).format('DD/MM/YYYY')}
                </div>
            </div>
            <div className="subTitle2">
                Takip No: {trackNumber}
            </div>
        </StyledCardTitleJobDetail>
    );
};

export default CardTitle;