import {useNavigate} from "react-router";
import {useSetRecoilState} from "recoil";
import {authAtom} from "../../state/auth.atom";
import {ENDPOINTS} from "../constants/apiConstants";
import HttpUtil from "../http/httpUtil";
import {deleteToken, setToken} from "./tokenUtils";


function useUserActions() {
    const setAuth =  useSetRecoilState(authAtom);
    const navigate = useNavigate();

    const login = async (data) => {
        const request = {
            method: 'POST',
            url: ENDPOINTS.USER.LOGIN,
            data,
        };
        const userResponse = await HttpUtil.makeRequest(request);
        if (userResponse.data?.loginStatus) {
            setToken(userResponse.data)
            setAuth(userResponse.data);
        }
        return userResponse.data;
    }

    const logout = () => {
        deleteToken();
        setAuth(null);
        setTimeout(() => {
            navigate('/login');
        }, 200)
    }


    const changePassword = async (data) => {
        const request = {
            method: 'POST',
            url: ENDPOINTS.USER.CHANGE_PASSWORD,
            data
        }
        const response = await HttpUtil.makeRequest(request);
        return response.data;
    }

    return {login, logout, changePassword}
}

export default useUserActions;