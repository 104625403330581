import React from 'react';
import styled from 'styled-components';

const StyledCardTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  
`
const CardTitle = (props) => {
    return (
        <StyledCardTitle>
            {props.title}
        </StyledCardTitle>
    );
};

export default CardTitle;