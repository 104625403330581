import {ENDPOINTS} from "../constants/apiConstants";
import instance from "../http/httpUtil";
import HttpUtil from "../http/httpUtil";

class CustomerApi{
    createCustomer(payload) {
        const request = {
            method: 'POST',
            url: ENDPOINTS.CUSTOMER.CREATE_CUSTOMER,
            data: payload,
        };
        return HttpUtil.makeRequest(request)
    }

    findCustomer(keyword) {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.CUSTOMER.FIND_CUSTOMER}/${keyword}`,
        };
        return HttpUtil.makeRequest(request)
    }

    async getCustomerList() {
        const request = {
            method: 'GET',
            url: ENDPOINTS.CUSTOMER.GET_CUSTOMER_LIST
        }
        return HttpUtil.makeRequest(request)
    }

    async updateCustomer(payload, id) {
        const request = {
            method: 'PUT',
            url: `${ENDPOINTS.CUSTOMER.UPDATE_CUSTOMER}/${id}`,
            data: payload
        }
        return HttpUtil.makeRequest(request);
    }

    async deleteCustomer(id) {
        const request = {
            method: 'DELETE',
            url: `${ENDPOINTS.CUSTOMER.DELETE_CUSTOMER}/${id}`,
        }
        return HttpUtil.makeRequest(request);
    }

    async getTotalCustomer() {
        const request = {
            method: 'GET',
            url: ENDPOINTS.REPORT.GET_TOTAL_CUSTOMER
        }
        return HttpUtil.makeRequest(request)
    }

    async getCustomerReport(customerId) {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.REPORT.GET_CUSTOMER_REPORT}/${customerId}`
        }
        return HttpUtil.makeRequest(request)
    }
}

export default new CustomerApi();