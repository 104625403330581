import {AUTH_TOKEN} from "../constants/apiConstants";

export const setToken = (userData) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(userData));
}

export const deleteToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}

export const getToken = () => {
    const tokenData = localStorage.getItem(AUTH_TOKEN);
    return tokenData ? 'Bearer ' + JSON.parse(tokenData)['token'] : null;
}