import React, {useState} from 'react';
import {Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Switch} from "antd";
import {cardTitle} from "../layout/cardTitle";
import DebounceSelect from "../selectBox/selectBox";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import locale from "antd/es/locale/tr_TR";
import TextArea from "antd/es/input/TextArea";
import {CURRENCY} from "../../common/constants/misc";
import moment from "moment";
import fetchCustomerList from "../../common/utils/getCustomerListUtil";

const { RangePicker } = DatePicker;


const formItemProps = {
    colon: false,
    labelCol: {span: 2, offset: 0},
    labelAlign: 'left',
    style: {width: '100%'}
}


const JobForm = (props) => {

    const {onFormFinish, onFormFinishFailed, showMusteriModal, jobDetail, formTitle} = props;
    const [value, setValue] = useState([]);

    const handleChangeSelectBox = (val) => {
    }


    return (
        <Form
            name="new_job_form"
            onFinish={onFormFinish}
            onFinishFailed={onFormFinishFailed}
        >
            <Card lg={6} md={8} sm={24} justify="center" title={cardTitle(formTitle)} style={{marginTop: '20px'}}>

                <Form.Item initialValue={jobDetail ? {label: jobDetail.customer.fullName, value: jobDetail.customer.id} : null} name="customer" label="Müşteri"
                           rules={[
                               {
                                   required: true,
                                   message: 'Lütfen Müşteri Seçiniz',
                               },
                           ]}
                           {...formItemProps}>
                    <DebounceSelect
                        showSearch
                        value={value}
                        placeholder="Müşteri Seç"
                        fetchOptions={fetchCustomerList}
                        notFoundContent={<Button type="dashed"  onClick={showMusteriModal} icon={<PlusOutlined />}>Yeni Müşteri Ekle</Button>}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        style={{
                            width: '100%',
                        }}
                    />

                </Form.Item>
                <Form.Item initialValue={jobDetail ? [moment(jobDetail.checkInDate), moment(jobDetail.checkOutDate)] : []} name="startEndDate" label="Geliş - Teslim Tarihleri"
                           rules={[
                               {
                                   required: true,
                                   message: 'Lütfen Tarihleri Seçiniz',
                               },
                           ]}
                           {...formItemProps}>
                    <RangePicker format="DD/MM/yyyy" locale={locale} style={{
                        width: '100%',
                    }} placeholder={["Geliş Tarihi", "Teslim Tarihi"]}/>
                </Form.Item>
            </Card>
            <Card lg={6} md={8} sm={24} justify="center" style={{marginTop: '10px'}} title={cardTitle('Ürünler')} >
                <Form.List name="products"  initialValue={jobDetail ? jobDetail.products : []}
                           rules={[
                               {
                                   validator: async (_, products) => {
                                       if (!products || products.length < 1) {
                                           return Promise.reject(new Error('En az bir ürün girilmelidir.'));
                                       }
                                   },
                               },
                           ]}>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, i) => (
                                <div key={i}>
                                    <Divider orientation="left" plain style={{fontSize: '17px'}}>
                                        Ürün - {i+1}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Divider>
                                    <Form.Item {...field} {...formItemProps} label="Ürün Tanımı" name={[field.name, 'description']}>
                                        <Input style={{width: '100%'}} placeholder="Ürün Tanımı (Ör:Mavi ceket, Kırmızı palto vs.)"/>
                                    </Form.Item>
                                    <Form.Item {...formItemProps} label="Özellikleri"  name={[field.name, 'characteristic']}>
                                        <Select mode="tags" style={{width: '100%'}} placeholder="Özellikleri"
                                                onChange={handleChangeSelectBox} options={getProperties()}/>
                                    </Form.Item>
                                    <Form.Item {...formItemProps} label="Yapılacak İşlemler"  name={[field.name, 'operations']}>
                                        <Select mode="tags" style={{width: '100%'}} placeholder="Yapılacak İşlemler"
                                                onChange={handleChangeSelectBox} options={getOperations()}/>
                                    </Form.Item>
                                    <Form.Item {...formItemProps} label="Ek Notlar" name={[field.name, 'notes']}>
                                        <TextArea showCount rows={6} maxLength={500} style={{width: '100%'}} placeholder="Ek Notlar"/>
                                    </Form.Item>
                                    <Row justify="space-between">
                                        <Col span={16}>
                                            <Form.Item {...formItemProps} label="Ücreti" name={[field.name, 'fee']}>
                                                <InputNumber
                                                    addonBefore={CURRENCY}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    style={{width: '100%'}}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item {...formItemProps} name={[field.name, 'paymentStatus']}  valuePropName="checked">
                                                <Switch size="small"  checkedChildren={'Ödendi'} unCheckedChildren={'Ödenmedi'} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Ürün Ekle
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Card>
            <Card lg={6} md={8} sm={24} justify="center" style={{marginTop: '10px'}}>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        Kaydet
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="secondary" htmlType="reset" style={{width: '100%'}}>
                        Sıfırla
                    </Button>
                </Form.Item>
            </Card>
        </Form>
    );
};



const operations = ['Kuru Temizleme', 'Ütüleme', 'Deri Temizleme', 'Leke Çıkarma', 'Perde Temizleme', 'Yıkama Hizmeti', 'Ücretsiz Servis'];
const properties = ['Yün', 'Perde', 'Lekeli', 'Yırtık', 'Deformeli', 'Dikiş Söküğü', 'Polyester', 'İpek', 'Hassas Ürün', 'Soluk Renkli'];
const getOperations = () => {
    return operations.map((o, i) => ({value: o, label: o}))
}

const getProperties = () => {
    return properties.map((o, i) => ({value: o, label: o}))
}



export default JobForm;