import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import { cardTitleJobDetail} from "../../components/layout/cardTitle";
import {Card, Badge, Descriptions, Form, Upload, Button, Modal} from "antd";
import {useNavigate, useParams} from "react-router";
import JobsApi from "../../common/api/jobsApi";
import {
    CameraOutlined, EditOutlined,
    ExclamationCircleOutlined, PrinterOutlined,
    UploadOutlined
} from "@ant-design/icons";
import MessageModal from "../../components/modal/messageModal";
import CameraModal from "../../components/modal/cameraModal";
import {CURRENCY_SYMB, JOB_STATUS} from "../../common/constants/misc";
import {ROUTES} from "../../common/constants/routeConstants";
import {getToken} from "../../common/utils/tokenUtils";
import {USER_TOKEN} from "../../common/constants/apiConstants";
import html2canvas from "html2canvas";
const { confirm } = Modal;

const StyledList = styled.ul`
  padding: 0 0px 0px 17px;
  list-style: circle;
`

const JobDetail = (props) => {

    let {trackNumber} = useParams();
    const navigate = useNavigate();
    const [jobDetail, setJobDetail] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [savePhotoStatus, setSavePhotoStatus] = useState({submitted: false, finished: false, failed: false});
    const [cameraModalVisible, setCameraModalVisible] = useState(false);
    const [activeProductId, setActiveProductId] = useState(0);
    const [productPhotos, setProductPhotos] = useState({});
    const [processingImgCount, setProcessingImgCount] = useState(0);

    const getJobDetail = () => {
        JobsApi.getJobDetail(trackNumber).then(response => {
            console.log('responxe=>', response.data);
            if (response.data) {
                setJobDetail(response.data);
            }
        }).catch(err => {
            console.error(err);
        })
    }
    const getProductPhotos = async () => {
        for (const product of jobDetail.products) {
            if (product?.photos?.length > 0) {
                for (const photo of product.photos) {
                 //   const response = await JobsApi.getProductPhoto(photo);
                    setProductPhotos((prevState) => {
                        if (prevState[product.id]) {
                            const img = prevState[product.id].find(o => o.uid === photo);
                            if (!img) {
                                return {...prevState, [product.id]: [...prevState[product.id],
                                        {
                                            url: `${process.env.REACT_APP_API_URL}/jobs/photo/original/${photo}`,
                                            name: photo,
                                            status: 'done',
                                            uid: photo,
                                            productId: product.id
                                        }]}
                            }
                        } else {
                            return {...prevState, [product.id]: [{
                                    url: `${process.env.REACT_APP_API_URL}/jobs/photo/original/${photo}`,
                                    name: photo,
                                    status: 'done',
                                    uid: photo,
                                    productId: product.id
                                }]};
                        }
                    });
                }
            }
        }
    }

    useEffect(() => {
        getJobDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        console.log('product photos=>', productPhotos);
        if (Object.keys(productPhotos).length === 0 && jobDetail) {
            getProductPhotos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productPhotos]);

    useEffect(() => {
        console.log('job detail=>', jobDetail);
        if (jobDetail) {
            setProductPhotos({});
        }
    }, [jobDetail]);

    const printDoc = () => {
        const domElement = document.querySelector('#content-22');
        html2canvas(domElement, {onclone: (document) => {
                for (const btn of document.querySelectorAll('button')){
                    btn.style.display = 'none';
                }
            }}).then((canvas) => {
            const img = canvas.toDataURL('image/jpeg');
            const image = new Image();
            image.src =  img;
            const w = window
            w.document.body.append(image.outerHTML);
            w.print();
        })
    }

    const deletePhoto = async (fileName) => {
        const response = await JobsApi.deletePhoto(fileName);
        console.log('response=>', response.data);
        return response.data;
    }

    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        // eslint-disable-next-line no-unused-expressions
        imgWindow?.document.write(image.outerHTML);
    };

    const onPhotoRemove = (event) => {
        console.log(event);
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Resim Silinecek Emin misin?',

            onOk() {
                deletePhoto(event.name).then((result) => {
                    console.log('result=>', result);
                    if (result) {
                        setProductPhotos((prevState) => {
                            const productImages = prevState[event.productId];
                            const imageIndex = productImages.findIndex(o => o.name === event.name);
                            if (imageIndex > -1) {
                                productImages.splice(imageIndex, 1);
                            }
                            return {...prevState, [event.productId]: productImages};
                        });
                    }

                });
            },

            onCancel() {
                console.log('Cancel');
            },
        });

    }

    const onPhotoShotSuccess = (photoList) => {
        console.log('photo list=>', photoList);

        photoList.forEach(newPhoto => {
            setProcessingImgCount(processingImgCount + 1);
            const formData = new FormData();
            formData.append('id-number-value', "id");
            formData.append('id-number-type', "id_card");

            fetch(newPhoto.url)
                .then(res => res.blob())
                .then(async (blob) => {
                    const file = new File([blob], `${newPhoto.name}`, {type: "image/jpeg"})
                    formData.append('photo', file);
                    fetch(`${process.env.REACT_APP_API_URL}/jobs/upload/${trackNumber}_${activeProductId}`, {
                        method: 'POST',
                        headers: {[USER_TOKEN]: getToken()},
                        body: formData
                    }).then((response) => response.text())
                        .then((data) => {
                            console.log('response=>', data);
                            const productIndex = jobDetail.products.findIndex(o => o.id === activeProductId);
                            setJobDetail((prevState) => {
                                const products = prevState.products;
                                products[productIndex].photos = [...products[productIndex].photos, data];
                                return {...prevState, products};
                            });
                        })
                        .catch((error) => console.log('response=>', error));
                });

        });

    }

    const onFormFinish = (values) => {
        console.log('form finished', values);
        console.log('jobDetail=>', jobDetail);
        setSavePhotoStatus({submitted: true, finished: false, failed: false});
        JobsApi.setProductPhotos(jobDetail).then(response => {
            console.log('update response=>', response);
            if (response.data) {
                setSavePhotoStatus({submitted: true, finished: true, failed: false});
                getJobDetail();
            } else {
                setSavePhotoStatus({...savePhotoStatus, finished: true, failed: true});
            }
            setModalVisible(true);
        }).catch(err => {
            console.error(err);
            setSavePhotoStatus({...savePhotoStatus, finished: true, failed: true});
        })
    }
    const onFormFinishFailed = (error) => {
        console.error(error);
        setSavePhotoStatus({submitted: true, finished: false, failed: true});

    }

    const closeMessageModal = () => {
        setModalVisible(false);
    }

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        if (e.file.response) {
            console.log('response=>', e.file.response);
            const productId = Number(e.file.response.split('_')[1]);
            console.log(productId);
            setJobDetail((prevState) => {
                const product = prevState.products.find(o => o.id === productId);
                console.log('products=>', product);
                product.photos = [...product.photos, e.file.response];
                return {...prevState, products: [...prevState.products]}
            })
        }

        return e?.fileList;
    };
    const openCameraHandler = (event, productId) => {
        event.stopPropagation();
        event.preventDefault();

        setActiveProductId(productId);
        setCameraModalVisible(true)
    }

    const goToEdit = (e) => {
        e.preventDefault();
        navigate(`${ROUTES.JOB_EDIT}/${trackNumber}`);
    }

    const onFileUploadChangeHandler = (e) => {
        console.log('change=>', e);

        if (e.file?.status === 'done') {
            console.log('response=>', e.file);
            if (e.file.response) {
                const productId = Number(e.file?.response?.split('_')[1]);
                console.log(productId);
                setJobDetail((prevState) => {
                    const product = prevState.products.find(o => o.id === productId);
                    console.log('products=>', product);
                    product.photos = [...product.photos, e.file.response];
                    return {...prevState, products: [...prevState.products]}
                })
            } else {
                alert('Dosya yüklenirken bir hata oluştu')
            }
        }

    }

    return (
        <div id="content-22">
            {jobDetail ?
                <Badge.Ribbon text={JOB_STATUS[jobDetail.status].TEXT} color={JOB_STATUS[jobDetail.status].COLOR}>
                    <Card lg={6} md={8} sm={24} justify="center"
                          title={cardTitleJobDetail(jobDetail.customer.fullName, jobDetail.createdAt, jobDetail.checkInDate, jobDetail.trackNumber)}
                          style={{marginTop: '20px'}}>
                        <Form name="job_photos"
                              onFinish={onFormFinish}
                              onFinishFailed={onFormFinishFailed}>
                            {jobDetail.products.map((product, i) =>

                                <div key={i}>
                                    <Badge.Ribbon text={product.paymentStatus ? 'Ödendi' : 'Ödenmedi'}
                                                  color={product.paymentStatus ? 'green' : 'red'}>
                                        <Descriptions title={product.description} bordered>
                                            <Descriptions.Item label="Özellikleri">
                                                <StyledList>
                                                    {product.characteristic.map((o, index) => <li key={index}>{o}</li>)}
                                                </StyledList>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Yapılacak İşlemler">
                                                <StyledList>
                                                    {product.operations.map((o, index) => <li key={index}>{o}</li>)}
                                                </StyledList>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Ek Notlar">{product.notes}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="Ücreti">{CURRENCY_SYMB[product.currency]}&nbsp;{product.fee}</Descriptions.Item>
                                        </Descriptions>
                                        <Form.Item
                                            name={`upload_${i}`}
                                            valuePropName={`fileList_${i}`}
                                            getValueFromEvent={normFile}
                                            style={{marginTop: '20px', textAlign: 'center'}}
                                        >
                                            <>
                                                <Upload key={i} className="upload-list-inline"
                                                        onPreview={onPreview}
                                                        onRemove={onPhotoRemove}
                                                        onChange={onFileUploadChangeHandler}
                                                        fileList={productPhotos[product.id]}
                                                        name="photo"
                                                        action={`${process.env.REACT_APP_API_URL}/jobs/upload/${trackNumber}_${product.id}`}
                                                        listType="picture" multiple accept="image/*"
                                                        headers={{[USER_TOKEN]: getToken()}}
                                                >

                                                    <Button icon={<UploadOutlined/>} size="large">Resim Yükle</Button>
                                                    <Button icon={<CameraOutlined/>} type="secondary" size="large" style={{marginLeft: '20px'}}
                                                            onClick={(event) => openCameraHandler(event, product.id)}>
                                                        Fotoğraf Çek
                                                    </Button>
                                                </Upload>
                                            </>

                                        </Form.Item>
                                    </Badge.Ribbon>
                                </div>
                            )}
                            <Form.Item>
                                <Button style={{width: '50%'}} type="secondary" htmlType="button" icon={<EditOutlined />}  onClick={goToEdit}>
                                    Düzenle
                                </Button>
                                <Button style={{width: '50%'}} type="secondary" htmlType="button"  icon={<PrinterOutlined />} onClick={printDoc}>
                                    Yazdır
                                </Button>
                                <Button  style={{marginTop: '10px'}} type="primary" size="large" htmlType="submit" block>
                                    Resimleri Onayla
                                </Button>
                            </Form.Item>
                        </Form>

                    </Card>
                </Badge.Ribbon>
                : ''
            }
            <MessageModal
                isModalVisible={modalVisible}
                setIsModalVisible={setModalVisible}
                status={savePhotoStatus.submitted && !savePhotoStatus.failed && savePhotoStatus.finished ? 'success' : 'warning'}
                title={savePhotoStatus.submitted && !savePhotoStatus.failed && savePhotoStatus.finished ? `Kayıt Başarılı` : 'Kayıt Başarısız'}
                subTitle={savePhotoStatus.submitted && !savePhotoStatus.failed && savePhotoStatus.finished ? "Fotoğraflarınız başarılı bir şekilde sisteme kaydedildi." : 'Kayıt esnasında bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'}
                btnType={savePhotoStatus.submitted && !savePhotoStatus.failed && savePhotoStatus.finished ? 'primary' : 'warning'}
                btnLabel={savePhotoStatus.submitted && !savePhotoStatus.failed && savePhotoStatus.finished ? 'Tamam' : 'Anladım'}
                btnClicked={closeMessageModal}
            />
            <CameraModal isModalVisible={cameraModalVisible} setIsModalVisible={setCameraModalVisible}
                         onSuccessPhotos={onPhotoShotSuccess}/>

        </div>
    );
};

export default JobDetail;
