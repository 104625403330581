import {ENDPOINTS} from "../constants/apiConstants";
import HttpUtil from "../http/httpUtil";


class JobsApi{
    async createNewJob(payload) {
        const request = {
            method: 'POST',
            url: ENDPOINTS.JOB.CREATE_NEW_JOB,
            data: payload,
        };
        return await HttpUtil.makeRequest(request)
    }

    getJobDetail(trackNumber) {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.JOB.GET_JOB_DETAIL}/${trackNumber}`,
        };
        return HttpUtil.makeRequest(request)
    }

    setProductPhotos(jobDetail) {
        const request = {
            method: 'PUT',
            url: `${ENDPOINTS.JOB.SET_PRODUCT_PHOTOS}/${jobDetail.trackNumber}`,
            data: jobDetail,
        };
        return HttpUtil.makeRequest(request);
    }

    getProductPhoto(photoId) {
        const request = {
            method: 'POST',
            url: `${ENDPOINTS.JOB.PRODUCT_PHOTO}`,
            data: {photoId}
        }
        return HttpUtil.makeRequest(request);
    }

    sendPhotoForm(urlParam, formData) {
        const request = {
            method: 'POST',
            url: `${ENDPOINTS.PHOTO.UPLOAD}/${urlParam}`,
            data: {formData}
        }
        return HttpUtil.makeRequest(request);
    }

    getJobList(queryParams) {
        const request = {
            method: 'GET',
            url: `${ENDPOINTS.JOB.GET_JOB_LIST}?${queryParams || ''}`,
        }
        return HttpUtil.makeRequest(request);
    }

    updateJobStatus(status, trackNumber) {
        const request = {
            method: 'PATCH',
            url: `${ENDPOINTS.JOB.UPDATE_STATUS}/${trackNumber}`,
            data: {status}
        }
        return HttpUtil.makeRequest(request);
    }

    updateProductPaymentStatus(productId, status) {
        const request = {
            method: 'PATCH',
            url: `${ENDPOINTS.JOB.UPDATE_PAYMENT_STATUS}/${productId}`,
            data: {status}
        }
        return HttpUtil.makeRequest(request);
    }

    deletePhoto(fileName) {
        const request = {
            method: 'DELETE',
            url: `${ENDPOINTS.PHOTO.DELETE}/${fileName}`
        }
        return HttpUtil.makeRequest(request);
    }

    updateJob(jobData, jobId) {
        const request = {
            method: 'PUT',
            url: `${ENDPOINTS.JOB.UPDATE_JOB}/${jobId}`,
            data: {jobData}
        }
        return HttpUtil.makeRequest(request);
    }
}

export default new JobsApi();