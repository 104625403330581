import axios from "axios";
import {USER_TOKEN} from "../constants/apiConstants";
import {getToken} from "../utils/tokenUtils";


class HttpUtil {
    _axios;

    constructor() {
        const token = getToken();
        this._axios = axios.create({
            baseURL : process.env.REACT_APP_API_URL,
            headers: {
                [USER_TOKEN]: token,
                "Content-Type": "application/json",
            },
        });
    }

    makeRequest(request) {
        request.headers = {...request.headers, [USER_TOKEN]: getToken()}
        return this._axios.request(request);
    }
}

export default new HttpUtil();